import React from "react";
import { EmailIcon, PhoneIcon } from "../CircleIcon/CircleIcon";
import Image from "../Image";
import { Body1, Subtitle2 } from "../Typography/Typography";
import styles from "./Employee.module.scss";

const Employee = ({ name, image, position, phone, email }) => {
  return (
    <div className={styles.employee}>
      <Image image={image} />
      <div>
        <Subtitle2>{name}</Subtitle2>
        <Body1>{position}</Body1>
        <Body1>
          <PhoneIcon phone={phone} />
          {phone}
        </Body1>
        <Body1>
          <EmailIcon email={email} />
          {email}
        </Body1>
      </div>
    </div>
  );
};

export default Employee;
