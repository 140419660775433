import React, { useState } from "react";
import SEO from "../components/seo";
import Layout from "../components/Layout/Layout";
import { graphql, useStaticQuery } from "gatsby";
import { Heading1, Heading5 } from "../components/Typography/Typography";
import BlockContent from "../components/BlockContent/BlockContent";
import Employee from "../components/Employee/Employee";
import FlytEmployeeList from "../components/FlytEmployeeList/FlytEmployeeList";
import Input from "../components/Input/Input";
import styles from "./pagestyles/ContactPage.module.scss";

import {
  sortByScore,
  splitNameStringSimilarity,
  stringSimilarity,
} from "../components/KommuneSearch/searchUtils";

const ContactPage = ({ location }) => {
  let {
    sanityContactPage: { employees, description, title },
    allSanityFlytEmployee,
  } = useStaticQuery(graphql`
    query {
      sanityContactPage(_id: { regex: "/contactPage$/" }) {
        employees: mainEmployees {
          name
          phone
          image {
            asset {
              fluid(maxWidth: 400) {
                ...GatsbySanityImageFluid
              }
            }
          }
          email
        }
        description: _rawDescription
        title
      }
      allSanityFlytEmployee {
        edges {
          node {
            name
            phone
            stilling
            email
            stillinger {
              name
              group {
                kommune {
                  name
                }
                year
              }
            }
          }
        }
      }
    }
  `);
  const [contactQuery, setContactQuery] = useState("");
  const [result, setResult] = useState([]);

  let flytEmployees = allSanityFlytEmployee.edges.map(({ node }) => ({
    ...node,
  }));

  const search = (query) => {
    let tempResults = flytEmployees
      .map((props) => ({
        ...props,
        score:
          props.name && props.stillinger
            ? Math.max(
                splitNameStringSimilarity(query, props.name),
                ...props.stillinger.map(({ group }) =>
                  splitNameStringSimilarity(query, group?.kommune?.name || "")
                )
              )
            : 0,
      }))
      .filter(({ score }) => score > 0.4);
    tempResults = sortByScore(tempResults);
    setResult(tempResults);
    setContactQuery(query);
  };

  return (
    <Layout location={location} standardPadding maxWidth>
      <SEO title="Kontakt" />
      <Heading1>{title}</Heading1>
      <BlockContent blocks={description} />
      <br />
      <div className={styles.employeeList}>
        {employees.map((employee, index) => (
          <Employee {...employee} key={`employee-${index}`} />
        ))}
      </div>
      <Heading5>Flyt-ledere</Heading5>
      <br />
      <Input
        placeholder="Navn eller kommune"
        icon="search"
        value={contactQuery}
        onChange={(e) => search(e.target.value)}
      />
      <br />
      <FlytEmployeeList
        isGlobal
        contactPage
        flytEmployees={contactQuery.length > 1 ? result : flytEmployees}
      />
    </Layout>
  );
};

export default ContactPage;
